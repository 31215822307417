import React from "react";
import ReactHowler from "react-howler";
import raf from "raf"; // requestAnimationFrame polyfill
import Button from "./components/Button";
import { Row, Col } from "react-bootstrap";
import Rec from "./components/Rec";
import MyDropzone from "./components/MyDropzone";



class FullControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      loaded: false,
      loop: false,
      mute: false,
      volume: 1.0,
      seek: 0.0,
      rate: 1,
      isSeeking: false,
      mode: "1",
      src:""
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleOnLoad = this.handleOnLoad.bind(this);
    this.handleOnEnd = this.handleOnEnd.bind(this);
    this.handleOnPlay = this.handleOnPlay.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.renderSeekPos = this.renderSeekPos.bind(this);
    this.handleLoopToggle = this.handleLoopToggle.bind(this);
    this.handleMuteToggle = this.handleMuteToggle.bind(this);
    this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this);
    this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this);
    this.handleSeekingChange = this.handleSeekingChange.bind(this);
    this.handleRate = this.handleRate.bind(this);
    this.handleMode = this.handleMode.bind(this);
    this.setBlob = this.setBlob.bind(this);
  }  
  
  
  // handleClick = () => {
  //   if (buttonName === "Play") {
  //     a.play();
  //     setButtonName("Pause");
  //   } else {
  //     a.pause();
  //     setButtonName("Play");
  //   }
  // };

   addFile = (e) => {
    console.log(e.target.files[0])
    console.log(URL.createObjectURL(e.target.files[0]))
    if (e.target.files[0]) {
      this.setState({
        src: URL.createObjectURL(e.target.files[0]),
      });
    }
  };


  componentWillUnmount() {
    this.clearRAF();
  }

  handleToggle() {
    this.setState({
      playing: !this.state.playing,
    });
  }

  handleOnLoad(e) {
    this.setState({
      // src: e.target.result,
      loaded: true,
      duration: this.player.duration(),
    });
  }

  handleOnPlay() {
    this.setState({
      playing: true,
    });
    this.renderSeekPos();
  }

  handleOnEnd() {
    this.setState({
      playing: false,
    });
    this.clearRAF();
  }

  handleStop() {
    this.player.stop();
    this.setState({
      playing: false, // Need to update our local state so we don't immediately invoke autoplay
    });
    this.renderSeekPos();
  }

  handleLoopToggle() {
    this.setState({
      loop: !this.state.loop,
    });
  }

  handleMuteToggle() {
    this.setState({
      mute: !this.state.mute,
    });
  }

  handleMouseDownSeek() {
    this.setState({
      isSeeking: true,
    });
  }

  handleMouseUpSeek(e) {
    this.setState({
      isSeeking: false,
    });

    this.player.seek(e.target.value);
  }

  handleSeekingChange(e) {
    this.setState({
      seek: parseFloat(e.target.value),
    });
  }

  renderSeekPos() {
    if (!this.state.isSeeking) {
      this.setState({
        seek: this.player.seek(),
      });
    }
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos);
    }
  }

  handleRate(e) {
    const rate = parseFloat(e.target.value);
    this.player.rate(rate);
    this.setState({ rate });
  }

  clearRAF() {
    raf.cancel(this._raf);
  }
  handleMode(e) {
    this.setState({
      mode: e.target.value,
    });
  }
  setBlob(e) {
    this.setState({
      src: e,
    });
  }
  // PreviewAudio(e) {

  //   this.setState({
  //     src: e.target.result,
  //   });
  //   this.handleOnLoad()
  // }

//   previewFile() {
//     const file = document.getElementById('fileAudio').files[0];
//     const reader = new FileReader();
// var url=""
//     // listen for 'load' events on the FileReader
//     reader.addEventListener("load", function () {
//       // change the preview's src to be the "result" of reading the uploaded file (below)
//       console.log(reader.result)
//       url = reader.result
//       // this.src = reader.result;
//     }, false);
//     this.receiveAudio(url)
//     // if there's a file, tell the reader to read the data
//     // which triggers the load event above
//     if (file) {
//       reader.readAsDataURL(file);
//     }
//   }
//   receiveAudio(payload) {
//     const blob = new Blob([payload.audio], { type: "audio/wav" });
//     const audioUrl = URL.createObjectURL(blob);
//     console.log(audioUrl)
//     this.setState({
//       src: audioUrl.split(":")[0],
//     });
//     return {
//       type: "audio/wav",
//       audio: audioUrl,
//     };
//   }

  render() {
    return (
      <div className="full-control">
        <Row>
          <ul>
            <li style={{width:"50%"}}>
              <input
                type="radio"
                name="mode"
                value="1"
                id="upload"
                checked={this.state.mode === "1"}
                onChange={this.handleMode}
              />
              <label htmlFor="upload"><b>Upload</b></label>
              <div className="check">
                <div className="inside"></div>
              </div>
            </li>
            <li style={{width:"50%"}}>
              <input
                type="radio"
                value="2"
                id="rec"
                checked={this.state.mode === "2"}
                name="mode"
                onChange={this.handleMode}
              />
              <label htmlFor="rec"><b>Record</b></label>
              <div className="check">
                <div className="inside"></div>
              </div>
            </li>
          </ul>
        </Row>

        {this.state.mode === "1" ? (
          <Row>
            <br />
            <MyDropzone/>
            {/* <input type="file" id="fileAudio" name="fileAudio" onChange={this.addFile} accept=".mp3,audio/*"/> */}
{/* <form class="form-container" enctype='multipart/form-data'>
	<div class="upload-files-container">
		<div class="drag-file-area">
			<h3 class="dynamic-message"> Drag & drop any file here </h3>
			<label class="label"> or <span class="browse-files"> <input type="file" class="default-file-input"/> <span class="browse-files-text">browse file</span> <span>from device</span> </span> </label>
		</div>
		<span class="cannot-upload-message"> <span class="material-icons-outlined">error</span> Please select a file first <span class="material-icons-outlined cancel-alert-button">cancel</span> </span>
		<div class="file-block">
			<div class="file-info"> <span class="material-icons-outlined file-icon">description</span> <span class="file-name"> </span> | <span class="file-size">  </span> </div>
			<span class="material-icons remove-file-icon">delete</span>
			<div class="progress-bar"> </div>
		</div>
		<button type="button" className="btn btn-primary upload-button"> Upload </button>
	</div>
</form> */}
          </Row>
        ) : this.state.mode === "2" ? (
          <Row>
            <br />
            <Rec setBlog={this.setBlob}/>
            <div id="rec-player"></div>
          </Row>
        ) : (
          <Row>{this.state.src}</Row>
          
        )}


        {/* <div className='toggles'>
          <label>
            Loop:
            <input
              type='checkbox'
              checked={this.state.loop}
              onChange={this.handleLoopToggle}
            />
          </label>
          <label>
            Mute:
            <input
              type='checkbox'
              checked={this.state.mute}
              onChange={this.handleMuteToggle}
            />
          </label>
        </div> */}

        {/* <p>
          {'Status: '}
          {this.state.seek.toFixed(2)}
          {' / '}
          {(this.state.duration) ? this.state.duration.toFixed(2) : 'NaN'}
        </p> */}
        {/* <div className='rate'>
          <label>
            Rate:
            <span className='slider-container'>
              <input
                type='range'
                min='0.1'
                max='3'
                step='.01'
                value={this.state.rate}
                onChange={this.handleRate}
              />
            </span>
            {this.state.rate.toFixed(2)}
          </label>
        </div> */}

        {/* <Button onClick={this.handleToggle}>
          {this.state.playing ? (
            <i className="fa fa fa-pause-circle-o"></i>
          ) : (
            <i className="fa fa fa-play-circle-o"></i>
          )}
        </Button>
        <Button onClick={this.handleStop}>
          <i className="fa fa-stop-circle-o"></i>
        </Button> */}
      </div>
    );
  }
}

export default FullControl;
