import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { LineWave } from "react-loader-spinner";
import axios from "axios";
import Result from "./Result";
import { sendRequestDotNet } from "../utils/helper";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export default function MyDropzone(props) {
  const [files, setFiles] = useState([]);
  const [result, setResult] = useState(false);
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "MP3/*": [],
    },
    onDrop: (acceptedFiles) => {
      console.log(acceptedFiles);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div className="mx-auto py-4">
      <audio src={file.preview} controls />
    </div>

    // <div style={thumb} key={file.name}>
    //   <div style={thumbInner}>
    //     {/* <img
    //       src={file.preview}
    //       style={img}
    //       // Revoke data uri after image is loaded
    //       onLoad={() => { URL.revokeObjectURL(file.preview) }}
    //     /> */}
    //   </div>
    // </div>
  ));

  function transcribe() {
    setResult(false);
    setLoader(true);
    sendRequestDotNet(
      "audio",
      {
        file: files[0],
      },
      {
          "Content-Type": "multipart/form-data"
      }
    ).then((rps) => {
          setLoader(false);
          setResult(true);
          setData(rps.response)
    });

    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'multipart/form-data',
    //                 'Access-Control-Allow-Origin':'*' },
    //     body:  files[0]
    // };
    // fetch('/api/audio', requestOptions)
    //     .then(response => response.json())
    //     .then(data => console.log(data));


    //   var test =  axios.post('api/audio', {
    //         file: files,
    //   }, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   }
    // )
    // console.log(test);

    // axios.post("/api/audio", {
    //     file: files,
    //   })
    //   .then((response) => {
    //     console.log(response);
    //   });

    // setResult(false);
    // setLoader(true);
    // setTimeout(() => {
    //   setLoader(false);
    //   setResult(true);
    // }, 3000);
    
    // setResult(true);
  }

  //   var renderSwitch= {
  //     switch(param) {
  //       case 'foo':
  //         return 'bar';
  //       default:
  //         return 'foo';
  //     }
  //   }
//   const handleFileSelected = (e) => {
//     const files = Array.from(e.target.files)
//     console.log("files:", files[0])

    
//     sendRequestDotNet(
//         "/audio",
//        files[0],
//         {
//             "Content-Type": "multipart/form-data"
//         }
//       ).then((rps) => {
//         console.log(rps);
//       });
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'multipart/form-data',
    //                 'Access-Control-Allow-Origin':'*' },
    //     body:  files[0]
    // };
    // console.log(files[0])
    // fetch('/api/audio', requestOptions)
    //     .then(response => response.json())
    //     .then(data => console.log(data));


//   }
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="container">
      <div
        {...getRootProps({
          className: "dropzone",
        })}
      >
        <input {...getInputProps()} />{" "}
        <p> Drag 'n' drop some files here, or click to select files </p>{" "}
      </div>{" "}
      <aside style={thumbsContainer}> {thumbs} </aside>
      {files.length > 0 && (
        <Button
          onClick={transcribe}
          disabled={loader}
          className="btn btn-light my-4"
        >
          {loader ? (
            <LineWave
              height="100"
              width="100"
              color="#1C75A2"
              ariaLabel="line-wave"
              wrapperStyle={{
                paddingLeft: "32px",
                marginTop: "-29px",
              }}
              wrapperClass=""
              visible={loader}
              firstLineColor=""
              middleLineColor=""
              lastLineColor=""
            />
          ) : (
            "Transcribe"
          )}{" "}
        </Button>
      )}{" "}
      <br /> {result && <Result data={data} />}{" "}
    </section>
  );
}
