import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import neutralW from "../neutral-w.png";
import neutral from "../neutral.png";
import sadW from "../sad-w.png";
import sad from "../sad.png";
import happyW from "../happy-w.png";
import happy from "../happy.png";
import fearW from "../fear-w.png";
import fear from "../fear.png";
import surpriseW from "../surprise-w.png";
import surprise from "../surprise.png";
import disgustW from "../disgust-w.png";
import disgust from "../disgust.png";
import angryW from "../angry-w.png";
import angry from "../angry.png";

const expressions =[neutralW,neutral,sadW,sad,happyW,happy,fearW,fear,surpriseW,surprise,disgustW,disgust,angryW,angry]

const Result = (props) => {
  const { children, className,data, ...otherProps } = props;
  const [showA, setShowA] = useState(true);

  const toggleShowA = () => setShowA(!showA);

function find() {
  if(data.Gender=="female"){
      switch(data.Emotion) {
    case "angry":
      return angryW;
    case "neutral":
      return neutralW;
    case "sad":
      return sadW;
    case "happy":
      return happyW;
    case "fear":
      return fearW;
    case "surprise":
      return surpriseW;
    case "disgust":
      return disgustW;
    default:
      // code block
  }
  }
else if(data.Gender=="male"){
    switch(data.Emotion) {
      case "angry":
        return angry;
      case "neutral":
        return neutral;
      case "sad":
        return sad;
      case "happy":
        return happy;
      case "fear":
        return fear;
      case "surprise":
        return surprise;
      case "disgust":
        return disgust;
      default:
      // code block
  }
}

}


  return (
    <>
    {
      data?
      <>
      <img
        height={200}
        // src={expressions[Math.floor(Math.random() * expressions.length)]}
        // src={data.Emotion == "angry"?
        //   angry:fear
        // }
        src={
          find()
        }
      />
      <div className="p-5" style={{ textAlign: "left" }}>
        <h5>Gender: {data.Gender}</h5>
        <h5>Emotion: {data.Emotion}</h5>
        <h5>Language: {data.Language}</h5>
        <h5>Transcription: {data.Transcription}</h5>
      </div>
      </>:
      <>
       <Toast bg='danger' show={showA} className="mx-auto" onClose={toggleShowA}>
          {/* <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Something went wrong! Please try again</strong>
            <small>11 mins ago</small>
          </Toast.Header> */}
          <Toast.Body>Something went wrong! Please try again</Toast.Body>
        </Toast></>
    }
    </>
  );
};

export default Result;
