import logo from './logo.svg';
import img from './img.png'
import './App.css';
import FullControl from './FullControl'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Navbar, Row } from 'react-bootstrap';
import NavComp from './components/NavComp'


function App() {
  return (
    <div className="App">
      <NavComp />
      <Row>
        <Col className='pt-5' sm={12} md={6} lg={5} xl={5}>
          <h3 className='text-left'>Inmobiles Transcription APP</h3>
          <FullControl/>
          </Col>
        <Col sm={12} md={6} lg={7} xl={7}>
        <img style={{width:"100%"}} src={img} />
        </Col>
      </Row>
      
    </div>
  );
}

export default App;
