import axiosdotnet from './axiosdotnet';



export const sendRequestDotNet = async(link, data = {}, headers = {}, method = "POST") => {

    // console.log(link, data, headers, method)
    let contentType = "application/json";
    if(data instanceof FormData)
        contentType = "multipart/form-data";


    headers = {
        ...headers,
        'content-type': "multipart/form-data"
    }

    let axiosInst = null;
    if(method === "POST")
        axiosInst = axiosdotnet.post(link, data, {headers: headers});
    else
        axiosInst = axiosdotnet.get(link, {headers: headers});


    var inst= await  axiosInst.then((response) => {
        console.log(response)
        return {
            response: response.data,
        };
    })
    .catch((error) => {
            // console.log(error);
        let MsgResponse = "Something went wrong! Please try again";
        return {
            error: true,
            message: MsgResponse,
        };
    });
    return inst;
        
}
