import * as React from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
// import Button from './Button';
import { Button } from "react-bootstrap";
import { LineWave } from "react-loader-spinner";
import Result from "./Result";
import { sendRequestDotNet } from "../utils/helper";

export default function Rec(props) {
  const recorderControls = useAudioRecorder();
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isPaused,
    recordingTime,
  } = useAudioRecorder();

  const [blob, setBlob] = React.useState("");
  const [dataToSend, setDataToSend] = React.useState();
  const [result, setResult] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [data, setData] = React.useState();

  function blobToFile(theBlob, fileName){
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    setDataToSend(theBlob)
    console.log(theBlob)
    return theBlob;
}
  const addAudioElement = (blob) => {
    setBlob(blob);
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    const recWrapper = document.getElementById("rec-player");
    recWrapper.replaceChildren();
    console.log(recWrapper);
    props.setBlog(url);
    blobToFile(blob,"rec")
    recWrapper.appendChild(audio);
  };

  function transcribe() {
      setResult(false);
      setLoader(true);
      sendRequestDotNet(
        "audio",
        {
          file: dataToSend,
        },
        {
            "Content-Type": "multipart/form-data"
        }
      ).then((rps) => {
            setLoader(false);
            setResult(true);
            setData(rps.response)
      });
    // setResult(true);

    //     axios.post('http://192.168.153.97:5001/api/audio', {
    //         file: files,
    //   }, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   }
    // )
  }

  return (
    <section className="container">
      <AudioRecorder
        onRecordingComplete={(blob) => addAudioElement(blob)}
        recorderControls={recorderControls}
      />
      <br />
      {/* <Button onClick={this.handleStop}>
         
        </Button> */}
      <div>{recorderControls.isPaused}</div>
      {/* <div>{recorderControls.recordingTime}</div> */}

      {/* <Button onClick={recorderControls.stopRecording}> <i className="fa fa-stop-circle-o"></i></Button> */}
      {blob && (
        <Button
          onClick={transcribe}
          disabled={loader}
          className="btn btn-light my-4"
        >
          {loader ? (
            <LineWave
              height="100"
              width="100"
              color="#1C75A2"
              ariaLabel="line-wave"
              wrapperStyle={{ paddingLeft: "32px", marginTop: "-29px" }}
              wrapperClass=""
              visible={loader}
              firstLineColor=""
              middleLineColor=""
              lastLineColor=""
            />
          ) : (
            "Transcribe"
          )}
        </Button>
      )}
      <br />
      {result && <Result data={data}/>}
    </section>
  );
}
