import axios from 'axios';

const instance = axios.create({
	baseURL: "/api/"
});

instance.defaults.headers.post['Accept'] = "*/*";
instance.defaults.headers.post["Access-Control-Allow-Origin"]="*"
instance.defaults.headers.post["Access-Control-Allow-Headers"]="Origin, X-Requested-With, Content-Type, Accept"

// instance.defaults.headers.post['content-type'] = "application/json";

///// only those interceptors will work, the ones in index.js won't /////
instance.interceptors.request.use(request => {
	// console.log(request);
	//Edit request config
	return request;
}, error => {
	console.log(error); // error.message
	return Promise.reject(error);
});

// instance.interceptors.response.use(response => {
// 	// console.log(response);
// 	//Edit response config
// 	return response;
// }, error => {
// 	console.log(error); // error.message
// 	return Promise.reject(error);
// });

export default instance;